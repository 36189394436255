import { graphql, Link, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
import { Column, ColumnIsThreeFifth, Columns, Container, Footer } from "./styled-components"
import * as React from "react"

type Data = {
  siteBuildMetadata: {
    buildTime: string;
  };
  allContentfulCategory: {
    edges: {
      node: {
        fields: {
          categorySlug: string;
        };
        name: string;
      }
    }[]
  };
  allContentfulCountry: {
    edges: {
      node: {
        fields: {
          countrySlug: string;
        };
        name: string;
      }
    }[]
  };
  allContentfulRegion: {
    edges: {
      node: {
        fields: {
          regionSlug: string;
        };
        name: string;
      }
    }[]
  }
}

const AppFooter = () => {
  const data: Data = useStaticQuery(
    graphql`
        query {
            siteBuildMetadata {
                buildTime(formatString: "MMMM YYYY", locale: "de")
            }
            allContentfulCategory(sort: {fields: name, order: ASC}) {
                edges {
                    node {
                        fields {
                            categorySlug
                        }
                        name
                    }
                }
            }
            allContentfulCountry(sort: {fields: name, order: ASC}) {
                edges {
                    node {
                        fields {
                            countrySlug
                        }
                        name
                    }
                }
            }
            allContentfulRegion(sort: {fields: name, order: ASC}) {
                edges {
                    node {
                        fields {
                            regionSlug
                        }
                        name
                    }
                }
            }
        }
    `,
  )
  return (
    <Footer>
      <Container>
        <Columns>
          <Column>
            <h4>Touren nach Kategorie</h4>
            {data.allContentfulCategory.edges.map(edge => {
              return (
                <div key={edge.node.name}>
                  <Link
                    className="link__internal"
                    to={edge.node.fields.categorySlug}
                  >{edge.node.name} </Link>
                </div>
              )
            })}
          </Column>
          <Column>
            <h4>Touren nach Land</h4>
            {data.allContentfulCountry.edges.map(edge => {
              return (
                <div key={edge.node.name}>
                  <Link
                    className="link__internal"
                    to={edge.node.fields.countrySlug}
                  >{edge.node.name} </Link>
                </div>
              )
            })}
          </Column>
          <ColumnIsThreeFifth>
            <h4>Touren nach Region/Bundesland</h4>
            <Columns>
              {[0, 1, 2].map((column) => {
                return (
                  <Column key={column}>
                    {data.allContentfulRegion.edges.filter((_, index) => index % 3 === column).map((edge: any) => {
                      return (
                        <div key={edge.node.name}>
                          <Link
                            className="link__internal"
                            to={edge.node.fields.regionSlug}
                          >{edge.node.name} </Link>
                        </div>
                      )
                    })}
                  </Column>
                )
              })}
            </Columns>
          </ColumnIsThreeFifth>
        </Columns>
      </Container>
      <Container css={
        css`
            text-align: center;
            color: rgba(0, 0, 0, 0.3);
          `}>
        <Columns>
          <Column>
            <Link className="link__footer" to={`/static/impressum/`}>Impressum</Link>
            <Link className="link__footer" to={`/static/ueber-uns/`}>Über uns</Link>
            <Link className="link__footer" to={`/static/datenschutz/`}>Datenschutz</Link>
            <Link className="link__footer" to={`/static/nutzungsbedingungen/`}>Nutzungsbedingungen</Link>
          </Column>
        </Columns>
        <Columns>
          <Column>
            <div>yovelino @ {data.siteBuildMetadata.buildTime}</div>
          </Column>
        </Columns>
      </Container>
    </Footer>
  )
}

export default React.memo(AppFooter)
