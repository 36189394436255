import styled from "@emotion/styled"
import { rhythm } from "../utils/typography"


export const cacheOptions = {
  // prefix styles with "custom-cache" instead of "css"
  // e.g. "c-c-d6wh4r"
  key: "c-c",
  // only prefix the following style properties
  prefix: (key: string) => {
    switch (key) {
      case "appearance":
      case "user-select":
      case ":placeholder":
        return true
      default:
        return false
    }
  },
}


const breakpoints = [1024, 1216, 1408]

const mq = breakpoints.map(
  bp => `@media screen and (min-width: ${bp}px)`,
)

export const primaryColor = `#2196F3`
export const heroGradient = `linear-gradient(141deg, #00a3e1 0%, #2196F3 71%, #3481fa 100%)`
const footerBgColor = "#fafafa"
const footerColor = "rgba(0, 0, 0, 0.4)"

export const Footer = styled.footer`
  background-color: ${footerBgColor};
  padding: ${rhythm(2)} ${rhythm(1)} ${rhythm(3)};
  color: ${footerColor};
  margin-top: ${rhythm(0.5)}
`

export const Container = styled.section`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  
  ${mq[0]}{
    max-width: 960px;
  }
  ${mq[1]}{
    max-width: 1152px;
  }
  ${mq[2]}{
    max-width: 1344px;
  }
`


export const ContainerOverview = styled(Container)`
    margin-top: ${rhythm(1)};
    min-height: ${rhythm(15)};
    padding: 0 ${rhythm(0.5)};
`

export const Columns = styled.div`
  display: block;
  margin-left: -${rhythm(0.5)};
  margin-right: -${rhythm(0.5)};
  margin-top: -${rhythm(0.5)};
  &:last-child {
    margin-bottom: -${rhythm(0.5)};
  }
  @media screen and (min-width: 769px), print {
    display: flex;
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${rhythm(0.5)};
`

export const ColumnIsThreeFifth = styled(Column)`
  @media screen and (min-width: 769px), print {
    flex: none;
    width: 60%;
  }
`
export const ColumnIsOneFifth = styled(Column)`

  @media screen and (min-width: 769px) {
    flex: none;
    width: 33%;
  }
  @media screen and (min-width: 1344px), print {
    flex: none;
    width: 20%;
  }
`
export const ColumnIsOneThird = styled(Column)`
  @media screen and (min-width: 769px), print {
    flex: none;
    width: 33%;
  }
`
